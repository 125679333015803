import ContentLoader from 'react-content-loader';
import { nafColor } from '@nafcore/theme';
import React from 'react';
import styled from 'styled-components';

export const SelectLoader = () => (
  <Wrapper>
    <ContentLoader
      uniqueKey="select-loader"
      width="100%"
      viewBox="0 0 450 64"
      preserveAspectRatio="xMidYMid meet"
      backgroundColor={nafColor.neutral.neutral1}
      foregroundColor={nafColor.neutral.neutral2}
    >
      <rect x="0" y="0" rx="4" ry="4" width="140" height="16" />
      <rect x="0" y="20" rx="4" ry="4" width="220" height="32" />
    </ContentLoader>
  </Wrapper>
);

const Wrapper = styled.div`
  resize: horizontal;
  height: auto;
`;
