import React, { useRef } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { Text, TextVariant } from '@naf/text';
import { Select } from '@naf/input';
import { UserInteraction, UserInteractionVariant } from '@naf/user-interaction';
import styled from 'styled-components';
import { GridCol } from '@naf/grid';
import { breakpoints, spacing } from '@naf/theme';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@naf/button';
import { ArrowForward } from '@styled-icons/material/ArrowForward';

import { AnimationWrapper } from '../../../components/skeleton-loaders/AnimationWrapper';
import { SelectLoader } from '../../../components/skeleton-loaders/SelectLoader';
import { actions } from '../../../redux/modules/centers';
import useSelector from '../../../redux/typedHooks';
import { CenterType } from '../../../../../types/centerType';

type Props = {
  centerId?: number;
  handleSelect: (option: Record<string, string>) => void;
  selectedCenter?: CenterType;
  slug: string;
};

const CenterSelect = ({ centerId, handleSelect, selectedCenter, slug }: Props) => {
  const dispatch = useDispatch();
  const {
    centerList: { isUpdating, data, error },
  } = useSelector((state) => state.centers);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const options =
    data && !isUpdating && data.map((center) => ({ label: center.title || '', value: center.centerId.toString() }));

  const isSelectReady = !!(data && !isUpdating && options);
  return (
    <SelectGridCol s="12" m="12" l="12" xl="12">
      <TransitionGroup>
        {isSelectReady ? (
          <Transition nodeRef={selectRef} in={isSelectReady} timeout={250} unmountOnExit mountOnEnter>
            {(state) => (
              <AnimationWrapper ref={selectRef} $state={state}>
                <Text variant={TextVariant.Header3}>Se hva som er tilgjengelig i ditt område:</Text>
                <SelectWrapper>
                  <StyledSelect
                    handleSelect={handleSelect}
                    options={options}
                    maxLines={6}
                    width="100%"
                    selected={
                      centerId
                        ? {
                            value: centerId.toString(),
                            label: selectedCenter?.title || '',
                          }
                        : undefined
                    }
                    placeholder="Velg ditt NAF senter"
                  />
                  {data && !error && !isUpdating && centerId && (
                    <StyledButtonLink to={`/${slug}/naf-senter/${selectedCenter?.link.data.slug}`}>
                      <StyledButton type="button" isNotClickable variant="secondary">
                        {`Gå til ${selectedCenter?.title}`}
                        <ArrowForward width={18} height={18} />
                      </StyledButton>
                    </StyledButtonLink>
                  )}
                </SelectWrapper>
              </AnimationWrapper>
            )}
          </Transition>
        ) : (
          <Transition nodeRef={selectRef} in={!isSelectReady} timeout={250} unmountOnExit mountOnEnter>
            {(state) => (
              <AnimationWrapper ref={selectRef} $state={state}>
                <SelectLoader />
              </AnimationWrapper>
            )}
          </Transition>
        )}
      </TransitionGroup>
      {error && (
        <UserInteraction
          maxWidth={300}
          title="Oops, noe gikk galt!"
          variant={UserInteractionVariant.Error}
          button={{
            onClick: () => {
              dispatch(actions.getCenters.request(undefined, undefined));
            },
            children: 'Prøv igjen',
            type: 'button',
            isLoading: isUpdating,
          }}
        />
      )}
    </SelectGridCol>
  );
};

const SelectGridCol = styled(GridCol)`
  margin-bottom: ${spacing.space8};
  margin-top: ${spacing.space32};
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.space16};

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    gap: ${spacing.space12};
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  height: ${spacing.space80};
  & > div {
    top: ${spacing.space80};
  }

  @media (max-width: ${breakpoints.m}) {
    height: 60px;
    & > div {
      top: 60px;
    }
  }
`;

const StyledButtonLink = styled(Link)`
  & span {
    display: flex;
    justify-content: space-between;
    gap: ${spacing.space16};
  }

  & > div {
    white-space: nowrap;
    height: ${spacing.space80};

    @media (max-width: ${breakpoints.m}) {
      height: 60px;
      white-space: wrap;
    }
  }
`;

// TODO pass down classname to unclickable button so that styles are applied here
const StyledButton = styled(Button)``;

export default CenterSelect;
