import ContentLoader from 'react-content-loader';
import { nafColor } from '@nafcore/theme';
import React from 'react';
import styled from 'styled-components';

export const ServiceProductLoader = () => (
  <Wrapper>
    <ContentLoader
      uniqueKey="serviceproduct-loader"
      width="100%"
      viewBox="0 0 100 50"
      preserveAspectRatio="xMidYMid meet"
      backgroundColor={nafColor.neutral.neutral1}
      foregroundColor={nafColor.neutral.neutral2}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100" height="50" />
    </ContentLoader>
  </Wrapper>
);

const Wrapper = styled.div`
  resize: horizontal;
  height: auto;
`;
