import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { breakpoints, spacing } from '@naf/theme';
import { GridRow, GridCol } from '@naf/grid';
import { Label } from '@naf/label';

const HeaderColorBox = styled.div`
  height: 267px;
  width: 100vw;
  position: absolute;
  left: 0;
  top: calc(-129px + 120px);
  z-index: -100;
  background: ${nafColor.neutral.neutral1};

  @media (max-width: ${breakpoints.m}) {
    height: 251px;
  }

  @media (max-width: ${breakpoints.s}) {
    height: 218px;
  }
`;

const HeaderGridCol = styled(GridCol)`
  box-sizing: border-box;
  background: #fff;
  margin-top: ${spacing.space40};
  padding-right: ${spacing.space48};
  max-width: 736px;

  @media (max-width: ${breakpoints.l}) {
    margin-top: ${spacing.space48};
  }

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space40};
    max-width: 579px;
  }

  p {
    margin: 0;
    margin-bottom: ${spacing.space8};
  }

  h1 {
    margin: ${spacing.space48} 0 ${spacing.space20};

    @media (max-width: ${breakpoints.m}) {
      margin: ${spacing.space32} 0 ${spacing.space12};
    }

    @media (max-width: ${breakpoints.s}) {
      margin: ${spacing.space20} 0 ${spacing.space8};
    }
  }
`;

const MainRow = styled(GridRow)`
  p {
    margin: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: 0;
    grid-row-gap: ${spacing.space20} !important;
  }
`;

const ShortCutCol = styled(GridCol)`
  a {
    height: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const StyledLabel = styled(Label)`
  margin-bottom: ${spacing.space8};
  margin-top: ${spacing.space12};
  display: flex;
  width: min-content;

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space8};
  }
`;

const IllustrationCol = styled(GridCol)`
  display: flex;
  align-items: flex-end;

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

const Img = styled.img`
  position: absolute;
  padding-bottom: ${spacing.space8};
`;

const LinkCol = styled(GridCol)`
  margin-top: ${spacing.space16};

  p,
  a {
    display: inline-block;
    white-space: pre-wrap;
    margin: 0;
  }

  @media (max-width: ${breakpoints.m}) {
  }
  @media (max-width: ${breakpoints.s}) {
    display: none;
  }
`;

const SectionCol = styled(GridCol)`
  margin-top: ${spacing.space48};
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space24};
  }

  h2 {
    margin: 0;
    margin-bottom: ${spacing.space24};
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  display: flex;
  &:hover {
    color: inherit;
  }
`;
const NotAvailableCol = styled(GridCol)`
  margin-top: ${spacing.space24};
  margin-bottom: -${spacing.space32}; // Counter Grid Gap

  h2 {
    margin: 0;
  }
  p {
    margin: ${spacing.space24} 0;
  }

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space8};
    margin-bottom: -${spacing.space20};
  }
`;

export default {
  HeaderColorBox,
  HeaderGridCol,
  MainRow,
  ShortCutCol,
  StyledLabel,
  IllustrationCol,
  Img,
  LinkCol,
  SectionCol,
  ExternalLink,
  NotAvailableCol,
};
